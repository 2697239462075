import { ContextService } from "../context/context-service";
import {
  DCognitoCredentials,
  DCognitoUser,
  DNameValue,
  DOpportunity,
} from "../model";

export class STOREAPI {
  static getStore(id: string) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: "",
        },
      };
      fetch(`/api/pub/store?id=${id}`, options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static getCategories(store: string) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: "",
        },
      };
      fetch(`/api/pub/store-categories?store=${store}`, options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static getCategory(id: number) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: "",
        },
      };
      fetch(`/api/pub/sku-category?id=${id}`, options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          console.log("Error: " + err);
          reject(err);
        });
    });
  }

  static getSkus(category: number) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Cache-Control": "No-Cache",
          "Content-Type": "application/json",
          Authorization: "",
        },
      };
      //      fetch(`/api/pub/skus-for-category?category=${category}`, options)
      fetch(`/api/pub/skus?category=${category}`, options)
        .then((response) => response.json())
        .then((json) => {
          resolve(json); // let client to deal with error/exception
        })
        .catch((err) => {
          console.log("Error: " + err);
          reject(err);
        });
    });
  }
}
