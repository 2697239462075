import { AnyARecord } from "dns";
import { DMap, DObject } from "./ubq";

export class UObject implements DObject {
  // for animation

  static RADIUS: number = 10;
  static WALK_TIMER: number = 1000;

  frameCount: number = 0;
  animationFrameId: number = -1;

  map: string = "";
  id: string = "";
  label: string = "";
  x: number = 0;
  y: number = 0;
  color: string = "#00BB00";
  size = UObject.RADIUS;
  step = 10;

  ox: number = 0; // old pos
  oy: number = 0;

  ctx: any = null;
  w: number = 0;
  h: number = 0;

  walkTimer: any;

  constructor(object: DObject, ctx: any) {
    this.map = object.map ? object.map : "";
    this.id = object.id;
    this.label = object.label;

    this.x = object.x;
    this.y = object.y;
    this.color = object.color ? object.color : "#00BB00";

    this.size = object.size ? object.size : UObject.RADIUS;
    this.step = object.step ? object.step : 10;

    this.ctx = ctx;
    this.w = this.ctx ? this.ctx.canvas.width : 0;
    this.h = this.ctx ? this.ctx.canvas.height : 0;

    this.ox = this.x;
    this.oy = this.y;

    if (this.ctx) this.walk();
    //this.render();
  }

  release() {
    window.cancelAnimationFrame(this.animationFrameId);
    clearTimeout();
  }

  // put it to a position
  go(x: number, y: number) {
    this.ctx.clearRect(
      this.ox - this.size - 2,
      this.oy - this.size - 2,
      this.size * 2 + 4,
      this.size * 2 + 4
    );
    this.ctx.clearRect(
      this.x - this.size - 2,
      this.y - this.size - 2,
      this.size * 2 + 4,
      this.size * 2 + 4
    );
    this.ox = this.x;
    this.oy = this.y;

    this.x = x;
    this.y = y;
  }

  walk() {
    // clear previous position: we add 2-4 pixels buffer just in case
    this.ctx.clearRect(
      this.ox - this.size - 2,
      this.oy - this.size - 2,
      this.size * 2 + 4,
      this.size * 2 + 4
    );
    this.ctx.clearRect(
      this.x - this.size - 2,
      this.y - this.size - 2,
      this.size * 2 + 4,
      this.size * 2 + 4
    );
    // clear previous position including the line
    /**
    this.ctx.clearRect(
      this.ox - this.size,
      this.oy - this.size,
      this.x + this.size,
      this.y + this.size
    );
     */

    this.ox = this.x;
    this.oy = this.y;

    this.x = UObject.randomWalk(this.x, this.w, this.step);
    this.y = UObject.randomWalk(this.y, this.h, this.step);

    clearTimeout(this.walkTimer);
    this.walkTimer = setTimeout(() => {
      this.walk();
    }, UObject.WALK_TIMER);
  }

  draw() {
    if (this.ctx) {
      //console.log("object:[" + this.x + "," + this.y + "]");
      //this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
      this.ctx.clearRect(
        this.x - this.size,
        this.y - this.size,
        this.size * 2,
        this.size * 2
      );
      //this.ctx.clearRect(this.ox - 10, this.oy - 10, 20, 20);

      this.ctx.fillStyle = this.color;
      this.ctx.beginPath();
      this.ctx.arc(
        this.x,
        this.y,
        this.size * Math.sin(this.frameCount * 0.05) ** 2,
        0,
        2 * Math.PI
      );
      this.ctx.fill();

      // draw a line
      /**
      this.ctx.beginPath();
      this.ctx.fillStyle = "#888888";
      this.ctx.moveTo(this.ox, this.oy);
      this.ctx.lineTo(this.x, this.y);
      this.ctx.stroke();
      **/
    }
  }

  render() {
    //if (this.ctx) {
    this.frameCount++;
    this.draw();
    this.animationFrameId = window.requestAnimationFrame(() => {
      this.render();
    });
    // setTimeout(() => {
    //  this.render();
    //}, 10);
    //}
  }
  // this method is for test/demo purpose only
  // it randomly walk (change position) an array of objects.

  static init() {
    return new UObject(
      {
        map: "",
        id: "object-1",
        label: "Test 1",
        x: 100,
        y: 100,
        color: "#00BB00",
      },
      null
    );
  }

  static random(min: number, max: number) {
    //return Math.floor(Math.random() * (max - min + 1)) + min;
    return Math.floor(Math.random() * (max - min) + min);
  }

  static randomWalk(x: number, max: number, step: number) {
    let nx = x + (UObject.random(0, 3) - 1) * step;
    while (x < max && (nx < 0 || nx > max)) {
      // add additional not-allowed step/position logic here (for example from a physical map)
      nx = x + (UObject.random(0, 3) - 1) * step;
    }
    return nx;
  }

  static randomInit(ctx: any, maxW: number, maxH: number) {
    return [
      new UObject(
        {
          id: "object-1",
          label: "Test 1",
          x: UObject.random(0, maxW),
          y: UObject.random(0, maxH),
          color: "#00BB00",
        },
        ctx
      ),
      new UObject(
        {
          id: "object-2",
          label: "Test 2",
          x: UObject.random(0, maxW),
          y: UObject.random(0, maxH),
          color: "#BBBB00",
        },
        ctx
      ),
      new UObject(
        {
          id: "object-3",
          label: "Test 3",
          x: UObject.random(0, maxW),
          y: UObject.random(0, maxH),
          color: "#D7BDE2",
        },
        ctx
      ),
      new UObject(
        {
          id: "object-4",
          label: "Test 4",
          x: UObject.random(0, maxW),
          y: UObject.random(0, maxH),
          color: "#F9E79F",
        },
        ctx
      ),
      new UObject(
        {
          id: "object-5",
          label: "Test 5",
          x: UObject.random(0, maxW),
          y: UObject.random(0, maxH),
          color: "#F0F3F4",
        },
        ctx
      ),
      new UObject(
        {
          id: "object-6",
          label: "Test 6",
          x: UObject.random(0, maxW),
          y: UObject.random(0, maxH),
          color: "#DAF7A6",
        },
        ctx
      ),
      new UObject(
        {
          id: "object-7",
          label: "Test 7",
          x: UObject.random(0, maxW),
          y: UObject.random(0, maxH),
          color: "#D35400",
        },
        ctx
      ),
      new UObject(
        {
          id: "object-8",
          label: "Test 8",
          x: UObject.random(0, maxW),
          y: UObject.random(0, maxH),
          color: "#000000",
        },
        ctx
      ),
      new UObject(
        {
          id: "object-9",
          label: "Test 9",
          x: UObject.random(0, maxW),
          y: UObject.random(0, maxH),
          color: "#2E86C1",
        },
        ctx
      ),
      new UObject(
        {
          id: "object-10",
          label: "Test 10",
          x: UObject.random(0, maxW),
          y: UObject.random(0, maxH),
          color: "#76448A",
        },
        ctx
      ),
    ] as UObject[];
  }
}
