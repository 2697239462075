import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import useScrollTrigger from "../hooks/scroll-trigger";
import { Button, Container, Fade, Grid } from "@mui/material";
import ThemeCard from "../components/card/theme-card";
import "../custom.d.ts";
import "./style.scss";
import Banner from "../components/banner/banner";
import ContactForm from "../components/form/contact-form";
import { BSGAPI } from "../auth/bsg-api";
import { ContextService } from "../context/context-service";
import { DWebContent } from "../model";
import Loading from "../components/loading/loading";
import AnimationIcon from "@mui/icons-material/Animation";

import * as io from "socket.io-client";

import * as THREE from "three";
import { Canvas, useFrame } from "@react-three/fiber";

import UMap from "./u-map";
import { IonicReactExternalProps } from "@aws-amplify/ui-react/lib-esm/react-component-lib/utils";
import { UObject } from "./u-object";
import IoTCard from "./iot-card";
import { DObject } from "./ubq";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";

const UAdmin = (props: any) => {
  //const { children, window } = props;
  const { t, i18n } = useTranslation("content");

  const context = useContext(UserContext);
  //const socket = useContext(SocketContext);

  //const [socket, setSocket] = useState(null as unknown as io.Socket);

  const [webContent, setWebContent] = useState({} as DWebContent);

  const myRefWe = useRef<HTMLDivElement>(null);

  const getTrigger = useScrollTrigger();

  const [triggerWe, setTriggerWe] = useState(
    getTrigger(myRefWe.current).trigger
  );

  const [loading, setLoading] = useState(true);

  const [status, setStatus] = useState("Ready");

  const [objects, setObjects] = useState([] as DObject[]);

  //const iot = props.iot as DObject;
  const [iot, setIot] = useState({
    map: "map-id-demo-123",
    id: "object-1",
    label: "Test 1",
    x: 0,
    y: 0,
    color: "#00BB00",
  } as DObject);

  let tracker = useRef({
    socket: null as unknown as io.Socket,
  });

  useEffect(() => {
    window.onscroll = () => {
      setTriggerWe(getTrigger(myRefWe.current).trigger);
    };

    /**
    BSGAPI.getWebContent("admin").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
      setLoading(false);
    });
    **/

    getIoTs();

    setLoading(false);

    window.scrollTo(0, 0);
  }, []);

  const connect = (): Promise<io.Socket> => {
    return new Promise<io.Socket>((resolve, reject) => {
      if (!tracker.current.socket || tracker.current.socket.disconnected) {
        console.log("Connecting .... web socket ...");
        tracker.current.socket = io.connect("/");
        tracker.current.socket.on("connect", () => {
          //  console.log("Connected ...." + tracker.current.socket.id);

          tracker.current.socket.emit("admin-join", {});
          // server sent initiation
          tracker.current.socket.on("s-send-iots", (objects) => {
            //console.log("Map welcome  ...." + msg);
            let myObjects = objects.map((obj: DObject) => {
              return new UObject(obj as DObject, null);
            });
            setObjects(myObjects);
          });

          resolve(tracker.current.socket);
        });
        tracker.current.socket.on("connect_error", () => {
          //  console.log("Connected ...." + tracker.current.socket.id);
          reject();
        });
      } else {
        resolve(tracker.current.socket);
      }
    });
  };

  const saveMe = (iot: DObject, isNew: boolean): Promise<DObject> => {
    // in future we could add more logic. Currently: simply return resolve promise
    return new Promise<DObject>((resolve, reject) => {
      if (isNew) {
        //setObjects(objects.push(iot));
        setObjects([...objects, iot]);
        addIoT(iot);
        resolve(iot);
      } else {
        console.log("SaveMe: " + iot.id);
        //setObjects(...objects, iot);
        addIoT(iot);
        resolve(iot);
      }
    });
  };

  // add an IOT device
  const getIoTs = () => {
    connect()
      .then((socket) => {
        //if (!socket) {
        socket.emit("admin-get-iots");
      })
      .catch(() => {
        console.log("something goes wrong");
      });
  };

  // add an IOT device
  const removeIoTs = () => {
    connect()
      .then((socket) => {
        //if (!socket) {
        socket.emit("admin-remove-iots");
      })
      .catch(() => {
        console.log("something goes wrong");
      });
  };

  // add an IOT device
  const addIoT = (iot: DObject) => {
    connect()
      .then((socket) => {
        //if (!socket) {
        socket.emit("admin-map-join", iot);
      })
      .catch(() => {
        console.log("something goes wrong");
      });
  };

  const generateRandomIoTs = () => {
    saveMe(
      {
        map: "map-id-demo-123",
        id: "iot-100",
        label: "Test 1",
        x: UObject.random(0, 800),
        y: UObject.random(0, 400),
        color: "#00BB00",
      },
      true
    );
    saveMe(
      {
        map: "map-id-demo-123",
        id: "iot-101",
        label: "iot-101",
        x: UObject.random(0, 800),
        y: UObject.random(0, 400),
        color: "#BBBB00",
      },
      true
    );
    saveMe(
      {
        map: "map-id-demo-123",
        id: "iot-102",
        label: "iot-102",
        x: UObject.random(0, 800),
        y: UObject.random(0, 400),
        color: "#00BBBB",
      },
      true
    );
    saveMe(
      {
        map: "map-id-demo-123",
        id: "iot-103",
        label: "iot-103",
        x: UObject.random(0, 800),
        y: UObject.random(0, 400),
        color: "#BB0000",
      },
      true
    );
    saveMe(
      {
        map: "map-id-demo-123",
        id: "iot-104",
        label: "iot-104",
        x: UObject.random(0, 800),
        y: UObject.random(0, 400),
        color: "#4400BB",
      },
      true
    );
    saveMe(
      {
        map: "map-id-demo-123",
        id: "iot-105",
        label: "iot-105",
        x: UObject.random(0, 800),
        y: UObject.random(0, 400),
        color: "#BBFF00",
      },
      true
    );
    saveMe(
      {
        map: "map-id-demo-123",
        id: "iot-106",
        label: "iot-106",
        x: UObject.random(0, 800),
        y: UObject.random(0, 400),
        color: "#44BB44",
      },
      true
    );
    saveMe(
      {
        map: "map-id-demo-123",
        id: "iot-107",
        label: "iot-107",
        x: UObject.random(0, 800),
        y: UObject.random(0, 400),
        color: "#AA0088",
      },
      true
    );
    saveMe(
      {
        map: "map-id-demo-123",
        id: "iot-108",
        label: "iot-108",
        x: UObject.random(0, 800),
        y: UObject.random(0, 400),
        color: "#BB4488",
      },
      true
    );
    saveMe(
      {
        map: "map-id-demo-123",
        id: "iot-109",
        label: "iot-109",
        x: UObject.random(0, 800),
        y: UObject.random(0, 400),
        color: "#BB8800",
      },
      true
    );
    saveMe(
      {
        map: "map-id-demo-123",
        id: "iot-110",
        label: "IoT-110",
        x: UObject.random(0, 800),
        y: UObject.random(0, 400),
        color: "#440088",
      },
      true
    );
  };

  return (
    <div>
      <Loading show={loading} />

      <div className="section-dark">
        <Container maxWidth="lg">
          <div>
            <h3 className="center warning-dark">
              Click on object to show info
            </h3>
          </div>
        </Container>
      </div>

      <Fade in={triggerWe} timeout={4000}>
        <div ref={myRefWe}>
          <Banner>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h1>{webContent.h1?.title}</h1>
              </Grid>
              {webContent.h1?.children?.map((child) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <h2>{child.content}</h2>
                  </Grid>
                );
              })}
            </Grid>
          </Banner>
        </div>
      </Fade>

      <div className="section-white">
        <Container maxWidth="lg">
          <Grid
            container
            rowSpacing={8}
            columnSpacing={2}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            alignContent="center"
          >
            <Grid container item xs={12} justifyContent="center">
              You have <span className="warning-dark">{objects?.length}</span>{" "}
              IoT devices.
              <h3 className="center">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    generateRandomIoTs();
                  }}
                >
                  <AnimationIcon /> Generate Random IoTs
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    removeIoTs();
                  }}
                >
                  <AutoDeleteIcon /> Remove IoTs
                </Button>
              </h3>
            </Grid>

            {objects?.map((obj, index) => {
              return (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  justifyContent="center"
                >
                  <IoTCard iot={obj} action={saveMe}></IoTCard>
                </Grid>
              );
            })}

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={4}
              lg={3}
              justifyContent="center"
            >
              <IoTCard iot={null} action={saveMe}></IoTCard>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className="center">
        <ContactForm />
      </div>
    </div>
  );
};

export default UAdmin;
