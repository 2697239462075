import {
  DBusiness,
  DCognitoCredentials,
  DCognitoUser,
  DCookie,
  DLinkedInCompositeProfile,
  DNameValue,
  DPerson,
  DRelayState,
  DUser,
} from "../model";
import Amplify, { API, Auth } from "aws-amplify";
import awsConfig from "../api/aws-amplify";
import { resolve } from "path/posix";
import { BSGAuth } from "../auth/bsg-auth";
import { attributesToProps } from "html-react-parser";
import { BSGAPI } from "../auth/bsg-api";

//const cookie = useCookie();

Amplify.configure(awsConfig);

const bsg_cookie_name = "_bsg";

export class ContextService {
  static CACHE_MAX_AGE: number = 300; // seconds
  static SITE_NAME: string = "Paisun Store";

  user: DUser = {
    authorized: false,
    cognitoCredentials: {
      AccessToken: null,
      ExpiresIn: 3600,
      TokenType: "Bearer",
      RefreshToken: null,
      IdToken: null,
    },
    cognitoUser: {
      Username: null,
      UserAttributes: [],
      //authenticated: false,
      //jwt: null,
      //username: null,
      //email: null,
    },
    person: {},
  };

  business: DBusiness = {};

  urlRoot: string = "";

  update: boolean = false;

  constructor(user?: DUser, business?: DBusiness) {
    if (user) this.user = user;
    this.user.cookie = this.getBSGCookies();

    if (business) this.business = business;

    if (
      window.location.port == "" ||
      window.location.port == "0" ||
      window.location.port == "80" ||
      window.location.port == "443"
    ) {
      this.urlRoot = `${window.location.protocol}//${window.location.hostname}`;
    } else {
      this.urlRoot = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
    }

    this.user.linkedInFirstname = "Guest";

    this.user.cognitoUser = {
      Username: null,
      UserAttributes: [],
      //authenticated: false,
      //username: "",
      //email: "",
      //jwt: "",
    };
  }

  init() {
    return new Promise<void>((resolve, reject) => {
      // retrieving business info
      const api = "business-bsg";
      const path = "";
      const myInit = {
        headers: {
          Authorization: "", // for public API/json, we set it empty. Otherwise, AWS will use it to valdiate
        },
      };

      // multiple promises
      Promise.all([
        this.cognitoGetUser().then((user) => {
          if (user != null) {
            this.bsgGetPerson().then((person) => {
              this.bsgSetPerson(person);
              this.setUser({ ...user, person: person });
              //console.log("resolved 1");
            });
          }
        }),
        this.cognitoGetCredentials().then((credentials) => {
          if (credentials != null) {
            this.setCognitoCredentials(credentials);
            this.setUser({ ...this.user, cognitoCredentials: credentials });
            //console.log("resolved 1");
          }
        }),

        BSGAPI.getBusiness().then((json) => {
          this.business = json as DBusiness;
        }),

        //API.get(api, path, myInit).then((business) => {
        //  this.business = business;
        //}),
      ]).then(() => {
        this.forceUpdate();
        resolve();
        //resolve({
        //  user: this.user,
        //  business: this.business,
        //});
      });
    });
  }

  forceUpdate() {
    this.update = !this.update;
    //console.log("this.update: " + this.update);
  }

  reset() {
    this.signOut();
  }

  getEmail() {
    //return "Test";
    /**/
    if (this.user.cognitoUser.UserAttributes != null) {
      let attribute = this.user.cognitoUser.UserAttributes!.find(
        (attr: DNameValue) => attr.Name == "email"
      ) as DNameValue;
      if (attribute != null) return attribute.Value;
      else return "";
    } else {
      return "";
    }
    /**/
  }

  signOut() {
    this.user = {
      authorized: false,
      linkedInFirstname: "Guest",
      cognitoCredentials: {
        AccessToken: null,
        ExpiresIn: 3600,
        TokenType: "Bearer",
        RefreshToken: null,
        IdToken: null,
      },
      cognitoUser: {
        Username: null,
        UserAttributes: [],
        //authenticated: false,
        //jwt: null,
        //username: null,
        //email: null,
      },
      person: {},
    };

    this.forceUpdate();
  }

  setCookie(cookieName: string, cookieValue: string, hourToExpire?: number) {
    let date = new Date();
    date.setTime(
      date.getTime() +
        (hourToExpire
          ? hourToExpire * 24 * 3600 * 1000
          : 365 * 24 * 3600 * 1000)
    );
    document.cookie =
      cookieName + "=" + cookieValue + "; expires=" + date.toUTCString();
  }

  getCookie(cookieName: string) {
    let cookieValue = "";
    let cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].split("=");
      //console.log("key: " + cookie[0] + " value: " + cookie[1]);

      if (cookie[0].trim() == cookieName) {
        cookieValue = cookie[1];
        return cookieValue;
      } else {
        //console.log("No match: [" + cookie[0] + "]");
      }
    }
    //console.log("Cookie value: " + cookieValue);
    return cookieValue;
  }

  getBSGCookies() {
    let val = this.getCookie(bsg_cookie_name).split(".");
    //console.log("val: " + val);

    let cookieUID = val.length > 0 ? val[0] : "";
    let cookiePrivacyConsent = val.length > 1 ? val[1] : "";

    return {
      cookieUID: cookieUID,
      cookiePrivacyConsent: cookiePrivacyConsent,
    } as DCookie;
  }

  setBSGCookies() {
    if (this.user.cookie == null) this.user.cookie = {};

    if (
      this.user.cookie.cookieUID == null ||
      this.user.cookie.cookieUID == ""
    ) {
      this.user.cookie.cookieUID = Math.random()
        .toString(36)
        .substr(2, 10)
        .toUpperCase();
    }
    if (
      this.user.cookie.cookiePrivacyConsent == null ||
      this.user.cookie.cookiePrivacyConsent == ""
    ) {
      this.user.cookie.cookiePrivacyConsent = "N";
    }

    let cookieValue =
      this.user.cookie?.cookieUID +
      "." +
      this.user.cookie?.cookiePrivacyConsent;

    this.setCookie(bsg_cookie_name, cookieValue);
  }

  setBusiness(business: DBusiness) {
    this.business = business;
  }

  setUser(user: DUser) {
    this.user = user;
  }

  setLinkedInState(linkedInState: DRelayState) {
    this.user.linkedInState = linkedInState;

    return this.encodeLinkedInState();
  }

  // encode linkedin state to a string
  encodeLinkedInState() {
    let timestamp = new Date().getTime();
    let uri = encodeURIComponent(
      this.user.linkedInState?.redirectLink?.uri as string
    );
    let external = this.user.linkedInState?.redirectLink?.external ? "Y" : "N";

    return `${timestamp}||${uri}||${external}`;
  }

  // decode from linkedin state
  decodeLinkedInState(state: string) {
    let val = state.split("||");
    let timestamp = val.length > 0 ? val[0] : -1;
    let uri = val.length > 1 ? decodeURIComponent(val[1]) : "";
    let external = val.length > 2 ? val[2] == "Y" : false;

    let linkedInState = {
      timestamp: timestamp as number,
      redirectLink: {
        external: external,
        uri: uri,
      },
    };
    this.user.linkedInState = linkedInState;

    return linkedInState;
  }

  setLinkedInProfile(compositeProfile: DLinkedInCompositeProfile) {
    this.user.linkedInProfile = compositeProfile.profile;
    this.user.linkedInEmail =
      compositeProfile.emailHandler["handle~"].emailAddress;

    //to make it convenient
    this.user.linkedInFirstname = compositeProfile.profile.localizedFirstName;
    this.user.linkedInLastname = compositeProfile.profile.localizedLastName;

    //console.log("LinkedIn: " + this.user.linkedInFirstname);

    if (this.user.linkedInEmail != null) {
      this.user.authorized = true;
    }

    this.forceUpdate();
  }

  setLinkedInEmail(email: string) {
    this.user.linkedInEmail = email;
  }

  setLanguage(lang: string) {
    this.user.language = lang;
  }

  setCognitoCredentials(credentials: DCognitoCredentials) {
    this.user.cognitoCredentials = credentials;
  }

  setCognitoUser(user: DCognitoUser) {
    this.user.cognitoUser = user;
  }

  bsgSetPerson(person: DPerson) {
    this.user.person = person;
  }

  cognitoGetUser() {
    //console.log("cognitoGetUser: " + JSON.stringify(this.user));
    return new Promise<DUser>((resolve, reject) => {
      BSGAuth.currentAuthenticatedUser()
        .then((user) => {
          //console.log("resolved 2");
          if (user != null) {
            this.setCognitoUser(user);
            resolve(this.user);
          } else {
            this.reset();
            resolve(this.user);
          }
        })
        .catch((err: any) => {
          //console.log("reject 2");
          // do nothing
          //reject(err);
          this.reset();
          throw err;
        });
    });
  }
  cognitoGetCredentials() {
    //console.log("cognitoGetUser: " + JSON.stringify(this.user));
    return new Promise<DCognitoCredentials | null>((resolve, reject) => {
      BSGAuth.currentCredentials()
        .then((credentials) => {
          //console.log("resolved 2");
          if (credentials != null) {
            this.setCognitoCredentials(credentials);
            resolve(credentials);
          } else {
            this.reset();
            resolve(null);
          }
        })
        .catch((err: any) => {
          //console.log("reject 2");
          // do nothing
          //reject(err);
          this.reset();
          throw err;
        });
    });
  }

  bsgGetPerson() {
    //console.log("cognitoGetUser: " + JSON.stringify(this.user));
    return new Promise<DPerson>((resolve, reject) => {
      BSGAuth.bsgCurrentPerson()
        .then((person) => {
          //console.log("resolved 2");
          if (person != null) {
            this.bsgSetPerson(person);
            resolve(person);
          } else {
            this.reset();
            resolve({});
          }
        })
        .catch((err: any) => {
          //console.log("reject 2");
          // do nothing
          //reject(err);
          this.reset();
          throw err;
        });
    });
  }

  cognitoSignup = (email: string, phone: string, password: string) => {
    return BSGAuth.signUp({
      username: email,
      password: password,
      //attributes: [
      email: email, // optional
      phone: phone, // optional - E.164 number convention
      // other custom attributes
      //"custom:my-bsg": "1",
      //"custom:person": person,
    });
  };

  cognitoConfirmSignup = (email: string, verificationCode: string) => {
    return Auth.confirmSignUp(email, verificationCode);
  };

  cognitoVerifyEmail = (email: string) => {
    return Auth.verifyCurrentUserAttribute(email);
  };

  cognitoVerifyEmailSubmit = (email: string, verificationCode: string) => {
    return Auth.verifyCurrentUserAttributeSubmit(email, verificationCode);
  };

  cognitoSignin = (username: string, password: string) => {
    return new Promise<void>((resolve, reject) => {
      //Auth.signIn({
      BSGAuth.signIn({
        username: username,
        password: password,
      }).then(
        (credentials: any) => {
          //console.log("credentials: " + JSON.stringify(credentials));
          this.setCognitoCredentials(credentials as DCognitoCredentials);

          Promise.all([
            BSGAuth.getUser(this.user.cognitoCredentials.AccessToken as string)
              .then((user: any) => {
                //console.log("user: " + JSON.stringify(user));
                this.setCognitoUser(user as DCognitoUser);
                BSGAuth.bsgGetPerson(this.user.cognitoCredentials, user)
                  .then((person: any) => {
                    //console.log("person: " + JSON.stringify(person));
                    this.bsgSetPerson(person as DPerson);
                  })
                  .catch((err: any) => {
                    //console.log("Error: " + JSON.stringify(err));
                  });
              })
              .catch((err: any) => {
                //console.log("Error: " + JSON.stringify(err));
              }),
          ]).then(() => {
            resolve();
          });

          //this.setCognitoUser(user)
          //this.forceUpdate();
        },
        (err: any) => {
          //console.log("Error: " + JSON.stringify(err));
          reject();
        }
      );
    });
  };

  cognitoOAuthSignin = (authorizationCode: string, redirectUri: string) => {
    return new Promise<void>((resolve, reject) => {
      //Auth.signIn({
      BSGAuth.oAuthSignIn({
        authorization_code: authorizationCode,
        redirect_uri: redirectUri,
      }).then(
        (credentials: any) => {
          //console.log("credentials: " + JSON.stringify(credentials));
          this.setCognitoCredentials(credentials as DCognitoCredentials);

          Promise.all([
            BSGAuth.getUser(this.user.cognitoCredentials.AccessToken as string)
              .then((user: any) => {
                //console.log("user: " + JSON.stringify(user));
                this.setCognitoUser(user as DCognitoUser);
                BSGAuth.bsgGetPerson(this.user.cognitoCredentials, user)
                  .then((person: any) => {
                    //console.log("person: " + JSON.stringify(person));
                    this.bsgSetPerson(person as DPerson);
                  })
                  .catch((err: any) => {
                    console.log("Error: " + JSON.stringify(err));
                  });
              })
              .catch((err: any) => {
                //console.log("Error: " + JSON.stringify(err));
              }),
          ]).then(() => {
            resolve();
          });
        },
        (err: any) => {
          //console.log("Error: " + JSON.stringify(err));
          reject();
        }
      );
    });
  };

  cognitoRefreshToken = (refreshToken: string) => {
    return new Promise<void>((resolve, reject) => {
      //Auth.signIn({
      BSGAuth.refreshToken({
        refreshToken: refreshToken,
      }).then(
        (credentials: any) => {
          //console.log("credentials: " + JSON.stringify(credentials));
          this.setCognitoCredentials(credentials as DCognitoCredentials);

          Promise.all([
            BSGAuth.getUser(this.user.cognitoCredentials.AccessToken as string)
              .then((user: any) => {
                //console.log("user: " + JSON.stringify(user));
                this.setCognitoUser(user as DCognitoUser);
                BSGAuth.bsgGetPerson(this.user.cognitoCredentials, user)
                  .then((person: any) => {
                    //console.log("person: " + JSON.stringify(person));
                    this.bsgSetPerson(person as DPerson);
                  })
                  .catch((err: any) => {
                    //console.log("Error: " + JSON.stringify(err));
                  });
              })
              .catch((err: any) => {
                //console.log("Error: " + JSON.stringify(err));
              }),
          ]).then(() => {
            resolve();
          });

          //this.setCognitoUser(user)
          //this.forceUpdate();
        },
        (err: any) => {
          //console.log("Error: " + JSON.stringify(err));
          reject();
        }
      );
    });
  };

  cognitoSignout = () => {
    return new Promise<void>((resolve, reject) => {
      BSGAuth.signOut().then(
        () => {
          //this.user.cognitoUser!.username = null;
          //this.user.cognitoUser!.authenticated = false;
          this.signOut();

          resolve();
        },
        (err) => {
          reject();
        }
      );
    });
  };

  cognitoChangePassword = (oldPassword: string, newPassword: string) => {
    return new Promise<void>((resolve, reject) => {
      //BSGAuth.currentAuthenticatedUser()
      BSGAuth.currentCredentials()
        .then((credentials) => {
          //console.log("User trying to change password: " + JSON.stringify(user));
          BSGAuth.changePassword({
            AccessToken: credentials.AccessToken,
            PreviousPassword: oldPassword,
            ProposedPassword: newPassword,
          })
            .then(() => resolve())
            .catch(() => reject());
        })
        .catch((err) => {
          //console.log("Err: " + err);
          reject();
        });
    });
  };
  cognitoForgotPassword = (username: string) => {
    return Auth.forgotPassword(username);
  };
  cognitoForgotPasswordSubmit = (
    username: string,
    code: string,
    password: string
  ) => {
    return Auth.forgotPasswordSubmit(username, code, password);
  };
  setCognitoState(state: DRelayState) {
    this.user.cognitoState = state;

    return this.encodeLinkedInState();
  }

  signin = (email: string, password: string) => {
    this.user.sessionId = null;
  };
  signup = (email: string, password: string) => {
    this.user.sessionId = null;
  };
  signout = () => {
    this.user.sessionId = null;
  };
  sendPasswordResetEmail = (email: string) => {
    this.user.sessionId = null;
  };

  confirmPasswordReset = (code: string, password: string) => {
    this.user.sessionId = null;
  };
}
