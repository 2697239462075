import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/user-context";

import useScrollTrigger from "../hooks/scroll-trigger";
import { Container, Fade, Grid } from "@mui/material";
import ThemeCard from "../components/card/theme-card";
import "../custom.d.ts";
import "./style.scss";
import Banner from "../components/banner/banner";
import ContactForm from "../components/form/contact-form";
import { BSGAPI } from "../auth/bsg-api";
import { ContextService } from "../context/context-service";
import { DWebContent } from "../model";
import Loading from "../components/loading/loading";

import * as THREE from "three";
import { Canvas, useFrame } from "@react-three/fiber";
import ThreeDemo from "../components/three/three-demo";
import Store from "../store/store";

const About = (props: any) => {
  //const { children, window } = props;
  const { t, i18n } = useTranslation("content");

  const context = useContext(UserContext);

  const [webContent, setWebContent] = useState({} as DWebContent);

  const myRefAbout = useRef<HTMLDivElement>(null);
  const myRefInvestor = useRef<HTMLDivElement>(null);
  const myRefPartner = useRef<HTMLDivElement>(null);

  const getTrigger = useScrollTrigger();

  const [triggerAbout, settriggerAbout] = useState(
    getTrigger(myRefAbout.current).trigger
  );
  const [triggerPartner, setTriggerPartner] = useState(
    getTrigger(myRefPartner.current).trigger
  );
  const [triggerInvestor, setTriggerInvestor] = useState(
    getTrigger(myRefInvestor.current).trigger
  );

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.onscroll = () => {
      settriggerAbout(getTrigger(myRefAbout.current).trigger);
      setTriggerPartner(getTrigger(myRefPartner.current).trigger);
      setTriggerInvestor(getTrigger(myRefInvestor.current).trigger);
    };

    BSGAPI.getWebContent("about").then((json) => {
      let webContent = json as DWebContent;
      setWebContent(webContent);
      document.title = `${ContextService.SITE_NAME}:${webContent.title}`;
      setLoading(false);
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Loading show={loading} />
      {webContent.theme && (
        <ThemeCard
          backgroundImg={webContent.theme.image}
          opacity={0.9}
          title={webContent.theme.title as string}
          description={webContent.theme.description as string}
          minHeight={webContent.theme.height}
          caption={webContent.theme.caption}
        >
          <Container maxWidth="md">
            <h1 className="center secondary-light">
              {webContent.theme.h1?.title}
            </h1>
            {webContent.theme.h1?.children?.map((child) => {
              return (
                <h2 className="center secondary-light">{child.content}</h2>
              );
            })}
            <h2 className="center secondary-light">
              {webContent.theme.h2?.title}
            </h2>
            {webContent.theme.h2?.children?.map((child) => {
              return (
                <h3 className="center secondary-light">{child.content}</h3>
              );
            })}
          </Container>
        </ThemeCard>
      )}

      <Fade in={triggerAbout} timeout={4000}>
        <div ref={myRefAbout}>
          <Banner>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h1>{webContent.h1?.title}</h1>
              </Grid>
            </Grid>
          </Banner>

          <div className="section-light">
            <Container maxWidth="md">
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignContent="flex-start"
              >
                {webContent.h1?.children?.map((child) => {
                  return (
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <h2 className="primary-dark">{child.content}</h2>
                    </Grid>
                  );
                })}
              </Grid>
            </Container>
          </div>
        </div>
      </Fade>

      <Fade in={triggerPartner} timeout={4000}>
        <div ref={myRefPartner}>
          <Banner>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h1>{webContent.h2?.title}</h1>
                {webContent.h2?.children?.map((child) => {
                  return <h2 className="primary-light">{child.content}</h2>;
                })}
              </Grid>
            </Grid>
          </Banner>

          <div className="section-light">
            <Container maxWidth="md">
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignContent="flex-start"
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <img src="https://my-bsg-asset.s3.amazonaws.com/company/bostonsoftwaregroup.png"></img>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </Fade>

      <Fade in={triggerInvestor} timeout={4000}>
        <div ref={myRefInvestor}>
          <Banner>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <h1>{webContent.h3?.title}</h1>{" "}
                {webContent.h3?.children?.map((child) => {
                  return <h2 className="primary-light">{child.content}</h2>;
                })}
              </Grid>
            </Grid>
          </Banner>

          <div className="section-light">
            <Container maxWidth="md">
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignContent="flex-start"
              >
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <img src="https://my-bsg-asset.s3.amazonaws.com/company/bostonsoftwaregroup.png"></img>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </Fade>

      <div className="center">
        <ContactForm />
      </div>
    </div>
  );
};

export default About;
