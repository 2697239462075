import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/user-context";

import {
  Button,
  Hidden,
  Slide,
  useScrollTrigger,
  useTheme,
} from "@mui/material";

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MapIcon from "@mui/icons-material/Map";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PieChartIcon from "@mui/icons-material/PieChart";
import AppsIcon from "@mui/icons-material/Apps";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import RoomIcon from "@mui/icons-material/Room";
import SchoolIcon from "@mui/icons-material/School";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import HearingIcon from "@mui/icons-material/Hearing";
import WorkIcon from "@mui/icons-material/Work";
import TodayIcon from "@mui/icons-material/Today";

import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";

import { useHistory } from "react-router-dom";
import { styles } from "../../theme/Theme";

import "./style.scss";
import logo from "./logo_lg.png";
import { ApplicationContext } from "../../context/application-context";
import { DLink, DMenu, DProfile } from "../../model";
import { API } from "aws-amplify";
import BNavItem from "./nav-item";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PinDropIcon from "@mui/icons-material/PinDrop";

const anchor: "bottom" | "left" | "right" | "top" = "right";

const useStyles2 = () => {
  return makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
  });
};

const Nav = (props: any) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  const { t, i18n } = useTranslation("content");

  const { context, contextUpdate, forceUpdate } = useContext(UserContext);

  let [profile, setProfile] = useState({} as DProfile);

  let [menu, setMenu] = useState({} as DMenu);

  let [navItems, setNavItems] = useState([] as DLink[]);

  const [email, setEmail] = useState("");

  async function getMyProfile() {
    //const apiName = "users";
    const apiName = "my-profile";
    const path = "";
    const myInit = {
      headers: {
        "Cache-Control": "No-Cache",
        //"Cache-Control": ContextService.CACHE_MAX_AGE,
        //Authorization: `Bearer ${context.user.cognitoUser?.jwt}`, // for public API/json, we set it empty. Otherwise, AWS will use it to valdiate
        Authorization: `Bearer ${context.user.cognitoCredentials?.AccessToken}`, // for public API/json, we set it empty. Otherwise, AWS will use it to valdiate
      },
      body: {
        user: context.user.cognitoUser,
        params: {
          //person: context.user.person?.id,
        },
      },
      //queryStringParameters: {
      //person: context.user.person?.id,
      //},
    };
    //let response = await API.get(apiName, path, myInit);
    //setJobs(response as DJob[]);

    API.post(apiName, path, myInit).then((response) => {
      if (response.status == "ok") {
        console.log("Data: " + JSON.stringify(response));
        setProfile(response.data as DProfile);

        setMenu(response.data.nav as DMenu);
      } else if (response.status == "token-expired") {
        context.setCognitoState({
          timestamp: new Date().getTime(),
          redirectLink: {
            external: false,
            uri: `/home`,
            //external: true,
            //uri: "https://my.bostonsoftwaregroup.com",
          },
        });
        history.push("/sign-in?flow=REFRESH_TOKEN_AUTH"); // TODO: push to token refresh auth page ...
      } else {
        context.cognitoSignout();
        forceUpdate();
      }
      //if (profile.nav) setMenu(profile.nav as DMenu);
    });
  }

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: any, open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      //if ((event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const routeToDrawer = (anchor: any, route: string) => {
    history.push(route);

    //toggleDrawer(anchor, false);
    setState({ ...state, [anchor]: false });

    handleClose();
  };

  const history = useHistory();

  //const classes = useStyles();
  const classes2: any = useStyles2();

  const theme = useTheme();
  const classes: any = styles(theme);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [anchorElTraining, setAnchorElTraining] = useState<null | HTMLElement>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    context.init().then(() => {
      //console.log(JSON.stringify(context.business.navItems));
      setNavItems(context.business.navItems as DLink[]);
      setEmail(context.getEmail() as string);
      getMyProfile();

      //forceUpdate();
    });
    // context.init().then(() => {
    //if (context.user.cognitoUser!.Username) {
    // for testing:
    //getMyProfile();
    // just for testing:
    //forceUpdate();
    //}
    // });
  }, []);

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar className="navbar">
        <Toolbar className="toolbar">
          <Button
            className="navButton"
            onClick={() => {
              history.push("/home");
            }}
          >
            <img src={logo} alt={"BSG Logo"} style={{ height: 50 }} />
          </Button>

          {navItems.map((item, index) => {
            return <BNavItem link={item} key={`navItem-${index}`} />;
          })}

          <div className="grow"></div>
          <div className="mobile-menu">
            <IconButton
              edge="start"
              color="secondary"
              aria-label="open drawer"
              onClick={toggleDrawer(anchor, true)}
            >
              <MenuIcon color="secondary" style={{ fontSize: 25 }} />
            </IconButton>
          </div>
        </Toolbar>

        <Drawer
          anchor={anchor}
          open={state["right"]}
          onClose={toggleDrawer(anchor, false)}
        >
          <List>
            <div style={{ width: "100%", textAlign: "right" }}>
              <IconButton
                color="warning"
                aria-label="close drawer"
                onClick={() => {
                  setState({ ...state, [anchor]: false });
                }}
              >
                <ExitToAppIcon color="warning" style={{ fontSize: 25 }} />
              </IconButton>
            </div>

            <div className="navItemHeader">Paisun Store</div>

            <ListItem
              dense={true}
              divider={true}
              button
              key="test"
              onClick={() => {
                routeToDrawer(anchor, "/demo");
              }}
            >
              <ListItemIcon>
                <PinDropIcon color="warning" />
              </ListItemIcon>
              <ListItemText className="navItemText" primary="Demo" />
            </ListItem>

            {navItems.map((item, index) => {
              if (item.mdDown) {
                return (
                  <Hidden mdUp={item.mdDown} key={`hid-${index}`}>
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key={`list-item-${index}`}
                      onClick={() => {
                        routeToDrawer(anchor, item.uri as string);
                      }}
                    >
                      <ListItemIcon>
                        <ArrowForwardIosIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        className="navItemText"
                        primary={item.text}
                      />
                    </ListItem>
                  </Hidden>
                );
              }
            })}

            {context.user.cognitoUser.Username ? (
              <>
                {menu.header_mybsg && (
                  <>
                    <div className="navItemHeader">My BSG</div>

                    {menu.item_test && (
                      <ListItem
                        dense={true}
                        divider={true}
                        button
                        key="list-item-home"
                        onClick={() => {
                          routeToDrawer(anchor, "/home");
                        }}
                      >
                        <ListItemIcon>
                          <HomeIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText className="navItemText" primary="Test" />
                      </ListItem>
                    )}

                    {menu.item_home && (
                      <ListItem
                        dense={true}
                        divider={true}
                        button
                        key="home1"
                        onClick={() => {
                          routeToDrawer(anchor, "/home");
                        }}
                      >
                        <ListItemIcon>
                          <HomeIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText className="navItemText" primary="Home" />
                      </ListItem>
                    )}

                    {menu.item_schedule && (
                      <ListItem
                        dense={true}
                        divider={true}
                        button
                        key="schedule"
                        onClick={() => {
                          routeToDrawer(anchor, "/schedule");
                        }}
                      >
                        <ListItemIcon>
                          <TodayIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText
                          className="navItemText"
                          primary="Schedule"
                        />
                      </ListItem>
                    )}

                    {menu.item_referral && (
                      <ListItem
                        dense={true}
                        divider={true}
                        button
                        key="referral"
                        onClick={() => {
                          routeToDrawer(anchor, "/referral");
                        }}
                      >
                        <ListItemIcon>
                          <ConnectWithoutContactIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText
                          className="navItemText"
                          primary="Referral"
                        />
                      </ListItem>
                    )}

                    {menu.item_document && (
                      <ListItem
                        dense={true}
                        divider={true}
                        button
                        key="documents"
                        onClick={() => {
                          routeToDrawer(anchor, "/documents");
                        }}
                      >
                        <ListItemIcon>
                          <InsertDriveFileIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText
                          className="navItemText"
                          primary="Documents"
                        />
                      </ListItem>
                    )}

                    {menu.item_payment && (
                      <ListItem
                        dense={true}
                        divider={true}
                        button
                        key="list-item-pay"
                        onClick={() => {
                          routeToDrawer(anchor, "/pay");
                        }}
                      >
                        <ListItemIcon>
                          <AttachMoneyIcon color="secondary" />
                        </ListItemIcon>
                        <ListItemText
                          className="navItemText"
                          primary="Payment"
                        />
                      </ListItem>
                    )}
                  </>
                )}

                {menu.header_project && (
                  <>
                    <div className="navItemHeader">My Projects</div>

                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="projects"
                      onClick={() => {
                        routeToDrawer(anchor, "/signin");
                      }}
                    >
                      <ListItemIcon>
                        <AppsIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        className="navItemText"
                        primary="My Projects"
                      />
                    </ListItem>

                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="signin"
                      onClick={() => {
                        routeToDrawer(anchor, "/signin");
                      }}
                    >
                      <ListItemIcon>
                        <PieChartIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText className="navItemText" primary="Clients" />
                    </ListItem>
                  </>
                )}

                {menu.header_recruting && (
                  <>
                    <div className="navItemHeader">Recruiting</div>
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="tasks"
                      onClick={() => {
                        routeToDrawer(anchor, "/signin");
                      }}
                    >
                      <ListItemIcon>
                        <EmailIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText className="navItemText" primary="Tasks" />
                    </ListItem>
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="test-1"
                      onClick={() => {
                        routeToDrawer(anchor, "/member/test");
                      }}
                    >
                      <ListItemIcon>
                        <WorkIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        className="navItemText"
                        primary="Positions"
                      />
                    </ListItem>
                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="candidates"
                      onClick={() => {
                        routeToDrawer(anchor, "/candidates");
                      }}
                    >
                      <ListItemIcon>
                        <PeopleAltIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        className="navItemText"
                        primary="Candidates"
                      />
                    </ListItem>
                  </>
                )}

                {menu.header_system && (
                  <>
                    <div className="navItemHeader">System - {email}</div>

                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="my-profile"
                      onClick={() => {
                        routeToDrawer(anchor, "/profile");
                      }}
                    >
                      <ListItemIcon>
                        <AssignmentIndIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        className="navItemText"
                        primary="My Profile"
                      />
                    </ListItem>

                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="change-password"
                      onClick={() => {
                        routeToDrawer(anchor, "/change-password");
                      }}
                    >
                      <ListItemIcon>
                        <LockIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        className="navItemText"
                        primary="Change Password"
                      />
                    </ListItem>

                    <ListItem
                      dense={true}
                      divider={true}
                      button
                      key="sign-out"
                      onClick={() => {
                        routeToDrawer(anchor, "/sign-out");
                      }}
                    >
                      <ListItemIcon>
                        <LogoutIcon color="warning" />
                      </ListItemIcon>
                      <ListItemText className="navItemText" primary="Logout" />
                    </ListItem>
                  </>
                )}
              </>
            ) : (
              <ListItem
                dense={true}
                divider={true}
                button
                key="Sign In"
                onClick={() => {
                  routeToDrawer(anchor, "/sign-in");
                }}
              >
                <ListItemIcon>
                  <HomeIcon color="warning" />
                </ListItemIcon>
                <ListItemText className="navItemText" primary="Sign In" />
              </ListItem>
            )}
          </List>
        </Drawer>
      </AppBar>
    </Slide>
  );
};

export default Nav;
